import jsPDF from "jspdf";
import { word } from "./multi-language";
import { officeDetail } from "./office-details";
import { superFormat } from "./data-format";
import { addPictures, lotsImg } from "./image-transformation";
import { mergeSchemeTable } from "./data-table";
let language = "spanish";
import {
  configStyle,
  frontPage,
  leftData,
  backCover,
  unitDetail,
  unitDetailPreview
} from "./style.js";

let doc;
let officeDetailData;
let coordsMap;
let style;
let dataFrontPage;
let packedPriceRule;
let purchaseScheme;
let contactSelected;
const imgOne =
  "https://res.cloudinary.com/capital28-investments/image/upload/v1658782999/Captura_de_Pantalla_2022-07-25_a_la_s_15.59.52.png";
const imgTwo =
  "https://res.cloudinary.com/capital28-investments/image/upload/v1658783005/Captura_de_Pantalla_2022-07-25_a_la_s_16.00.52.png";

//Pendiente...
const imgLots = {
  imgOne:
    "https://res.cloudinary.com/di6cg0ywy/image/upload/v1653667089/ARRECIFE-LOTIFICACION-LOTES-RIVIERA-MAYA_2_qnudvk.jpg",
  imgTwo:
    "https://res.cloudinary.com/di6cg0ywy/image/upload/v1658942073/MASTERPLAN-XPU-HA-BEACH-COMPLETO---DICIEMBRE-2021_igty77.jpg"
};

export function generateQuote(structure, type) {
  language = getLanguage(structure);
  switch (type) {
    //LOTES
    case "lots":
      return quoteLots(structure);
    //OFICINAS
    case "offices":
      return quoteOffice(structure);
    //DEPARTAMENTOS
    case "department":
      return quoteDepartment(structure);
    default:
      return quoteOffice(structure);
  }
}

export async function layout(structure) {
  //Falta agregar el swch..
  doc = jsPDF("l", "mm", [677.3, 381]);
  frontPage(doc, dataFrontPage, structure.real_estate_group_id);
  leftData(doc, contactSelected, dataFrontPage);
  officeDetail(doc, officeDetailData.dataTable, language);
  //Hoja con informacion del lote...
  await addPictures(doc, coordsMap);
  // Si Zenica, agregar información de la Unidad
  if (structure.real_estate_group_id === "610412d0707aaaa569a2000a") {
    await unitDetail(doc, structure);
  }
  mergeSchemeTable(doc, packedPriceRule, purchaseScheme, false, language);
  backCover(doc);
  //doc.output("dataurlnewwindow");
  doc.save(
    `${dataFrontPage.nameLead.toUpperCase()}-${dataFrontPage.nameDev.toUpperCase()}.pdf`
  );
  doc = null;
  return true;
}

//Vista Previa sin el mapeado.. intentar agregarle una imagen de referencia para señalar que alli va la vista previa...
export function quotePreview(structure, type) {
  language = getLanguage(structure);
  switch (type) {
    //LOTES
    case "lots":
      return quotePreviewLots(structure);
    //OFICINAS
    case "offices":
      return quotePreviewOffices(structure);
    //DEPARTAMENTOS
    case "department":
      return quotePreviewDepartment(structure);
    default:
      return quotePreviewOffices(structure);
  }
}

function getAllData(structure) {
  officeDetailData = structure.properties
    ? superFormat(structure.properties, language)
    : null;
  if (structure.coords_map) {
    coordsMap = structure.coords_map;
  }
  style = structure.style;
  dataFrontPage = structure.dataFrontPage;
  packedPriceRule = structure.packedPriceRule;
  packedPriceRule.totalMeters = officeDetailData.totalMeters;
  packedPriceRule.nameDev = dataFrontPage.nameDev;
  const developmentName = dataFrontPage?.nameDev?.toLowerCase() ?? "";
  purchaseScheme = structure.purchaseScheme.map(item => {
    if (
      item.text === word(19, language) &&
      ["ikonic tower"].includes(developmentName)
    ) {
      return { ...item, text: word(94, language) };
    }
    return item;
  });
  purchaseScheme = purchaseScheme.map(item => {
    if (
      item.text.includes(word(45, language)) &&
      ["zelva 44"].includes(developmentName)
    ) {
      return { ...item, description: word(95, language) };
    }
    return item;
  });
  contactSelected = structure.contactSelected;
}

function imgs(imgOne, imgTwo) {
  doc.addImage(imgOne, "JPEG", 120, 210, 200, 150);
  doc.addImage(imgTwo, "JPEG", 380, 195, 180, 180);
}
//Funciones de vista previa del pdf.
function quotePreviewOffices(structure) {
  getAllData(structure, language);
  doc = jsPDF("l", "mm", [677.3, 381]);
  configStyle(structure.style, language);
  frontPage(doc, structure.dataFrontPage, structure.real_estate_group_id);
  leftData(doc, structure.contactSelected, structure.dataFrontPage);
  //Office Detail...
  officeDetailData = structure.properties
    ? superFormat(structure.properties, language)
    : null;
  //Datos a pintar en el office detail
  officeDetail(doc, officeDetailData.dataTable, language);

  imgs(imgOne, imgTwo);
  mergeSchemeTable(doc, packedPriceRule, purchaseScheme, false, language);
  backCover(doc);

  return doc;
}
function quotePreviewLots(structure) {
  getAllData(structure, language);
  doc = jsPDF("l", "mm", [677.3, 381]);
  configStyle(structure.style, language);
  frontPage(doc, structure.dataFrontPage, structure.real_estate_group_id);
  leftData(doc, structure.contactSelected, structure.dataFrontPage);

  //Hoja con información del lote...
  //Error.. tengo que llamarlo dos veces al momento de visualizar pdf en configuración
  unitDetailPreview(doc, structure.contactSelected, structure.properties);
  unitDetail(doc, structure);

  //Hoja con imagenes de mapeado en grande...
  lotsImg(doc, imgLots.imgOne, imgLots.imgTwo, structure);
  mergeSchemeTable(doc, packedPriceRule, purchaseScheme, true, language);
  backCover(doc);
  return doc;
}
function quotePreviewDepartment(structure) {
  getAllData(structure);
  doc = jsPDF("l", "mm", [677.3, 381]);
  configStyle(structure.style, language);
  frontPage(doc, structure.dataFrontPage, structure.real_estate_group_id);
  leftData(doc, structure.contactSelected, structure.dataFrontPage);

  //Hoja con información del lote...
  //Error.. tengo que llamarlo dos veces al momento de visualizar pdf en configuración
  unitDetailPreview(doc, structure.contactSelected, structure.properties);
  unitDetail(doc, structure);

  //Hoja con imagenes de mapeado en grande...
  lotsImg(doc, imgLots.imgOne, imgLots.imgTwo, structure);
  mergeSchemeTable(doc, packedPriceRule, purchaseScheme, true, language);
  backCover(doc);
  return doc;
}

//Funciones para generar cotizacion descargable..
function quoteOffice(structure) {
  getAllData(structure, language);
  configStyle(style, language);
  return layout(structure);
}
async function quoteLots(structure) {
  getAllData(structure, language);
  doc = jsPDF("l", "mm", [677.3, 381]);
  configStyle(structure.style, language);
  frontPage(doc, structure.dataFrontPage, structure.real_estate_group_id);
  leftData(doc, structure.contactSelected, structure.dataFrontPage);

  //Hoja con informacion del lote...
  await unitDetail(doc, structure);
  //Hoja con imagenes de mapeado en grande...
  /*   lotsImg(doc, imgLots.imgOne, imgLots.imgTwo);
  addPicturesLots */
  //await addPicturesLots(doc, coordsMap, language, structure);
  mergeSchemeTable(doc, packedPriceRule, purchaseScheme, true, language);
  backCover(doc);
  doc.save(
    `${dataFrontPage.nameLead.toUpperCase()}-${dataFrontPage.nameDev.toUpperCase()}.pdf`
  );
  doc = null;
  return true;
}

async function quoteDepartment(structure) {
  getAllData(structure, language);
  doc = jsPDF("l", "mm", [677.3, 381]);
  configStyle(structure.style, language);
  frontPage(doc, structure.dataFrontPage, structure.real_estate_group_id);
  leftData(doc, structure.contactSelected, structure.dataFrontPage);
  await unitDetail(doc, structure);
  mergeSchemeTable(
    doc,
    packedPriceRule,
    purchaseScheme,
    true,
    language,
    structure.dataFrontPage
  );
  backCover(doc);
  doc.save(
    `${dataFrontPage.nameLead.toUpperCase()}-${dataFrontPage.nameDev.toUpperCase()}.pdf`
  );
  doc = null;
  return true;
}
function getLanguage(structure) {
  return structure?.language ? structure.language : "spanish";
}
