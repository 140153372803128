import { background, imgFrontLogoCenter } from "./style";
import { word } from "./multi-language";
let primaryColor = "#000000";
let secondaryColor = "#ffffff";
let language = "spanish";
const monedaFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});
export function generateTableGeneral(doc, structure, propertie) {
  language = getLanguage(structure);
  generateTablePropertie(doc, structure, propertie);
}

function generateTablePropertie(doc, structure, propertie) {
  // const nameDevelopment = structure.dataFrontPage.nameDev ?? "";
  primaryColor = structure.style.container_one ?? "#000000";
  secondaryColor = structure.style.container_two ?? "#ffffff";
  const wantedTableWidth = 400;
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = (pageWidth - wantedTableWidth) / 2;
  const percentage_down_payment =
    structure.packedPriceRule?.seletedPriceRules?.percentage_down_payment ??
    100;
  const priceRules = [
    ...structure.packedPriceRule.priceRuleApplied.priceRulesDiscount,
    ...structure.packedPriceRule.priceRuleApplied.extraDiscounts
  ];
  const months =
    structure.packedPriceRule?.seletedPriceRules?.months_agreed ?? 0;
  doc.addPage();
  const building = propertie.building.total ?? propertie.building.construction;
  const currency = propertie.pricing.currency;
  const pricePropertie = propertie.pricing?.price ?? 0;
  const totalDiscounts = getTotalDiscounts(pricePropertie, priceRules);
  const percentageTotalDiscounts = (
    (totalDiscounts / pricePropertie) *
    100
  ).toFixed(0);
  const namePropertie = propertie.floor.name;
  const nameLote = propertie.code;
  const priceFinal = pricePropertie - totalDiscounts;
  const enganche = (priceFinal * percentage_down_payment) / 100;
  // Apartado o Deposito
  const guarantee_deposit =
    structure.packedPriceRule?.seletedPriceRules?.guarantee_deposit ?? 0;
  // Financiamiento en porcentaje
  const percentage_financing =
    structure.packedPriceRule?.seletedPriceRules?.financing ?? 0;
  background(doc);
  const { posY, imgHeight } = imgFrontLogoCenter(doc);
  const title = word(102, language) ?? "Cotización";
  generateTitleTable(doc, title, secondaryColor, posY + imgHeight + 25);
  doc.autoTable({
    theme: "plain",
    startY: posY + imgHeight + 40,
    head: [
      [
        {
          content: "Id"
        },
        {
          content: "Content"
        }
      ]
    ],
    body: [
      ["Nombre de la propiedad", `${namePropertie}`],
      ["Nombre del lote", `Lote ${nameLote}`],
      ["Superficie en m²", `${building} m²`],
      ["Precio m²", `${monedaFormatter.format(pricePropertie / building)}`],
      ["Porcentaje de Enganche", `${percentage_down_payment}%`],
      ["Enganche", `${monedaFormatter.format(enganche)}`],
      ["Apartado", `${monedaFormatter.format(guarantee_deposit)}`],
      ["Precio de Lista", `${monedaFormatter.format(pricePropertie)}`],
      ["Tipo de Moneda", `${currency}`],
      [
        "Descuento Total",
        `${monedaFormatter.format(totalDiscounts)} (${
          isNaN(percentageTotalDiscounts) ? 0 : percentageTotalDiscounts
        }%)`
      ],
      ["Precio final", `${monedaFormatter.format(priceFinal)}`],
      ["Porcentaje de Financiamiento", `${percentage_financing}%`],
      ["Plazo", `${months}`]
    ],
    margin: { left: margin, right: margin },
    showHead: false,
    bodyStyles: {
      fontSize: 20
    },
    columnStyles: {
      0: {
        cellWidth: 200,
        fillColor: secondaryColor,
        textColor: primaryColor
      }, // Columna 'ID' con ancho de 20 unidades
      1: {
        cellWidth: 200,
        fillColor: primaryColor,
        textColor: secondaryColor
      } // Columna 'Nombre' con ancho de 50 unidades
    },
    styles: {
      lineColor: primaryColor,
      halign: "center",
      fontStyle: "light"
    },
    allSectionHooks: true,
    // Use for customizing texts or styles of specific cells after they have been formatted by this plugin.
    // This hook is called just before the column width and other features are computed.
    didParseCell: function(data) {
      if (data.row.section === "body" && data.cell === data.row.cells[0]) {
        if (data.row.index === 12) {
          data.cell.styles.fillColor = secondaryColor; // cell background color
          data.cell.styles.lineColor = primaryColor; // cell border color
          data.cell.styles.lineWidth = {
            bottom: 0.5 // only bottom border will be painted
          };
        } else {
          data.cell.styles.fillColor = secondaryColor; // cell background color
          data.cell.styles.lineColor = primaryColor; // cell border color
          data.cell.styles.lineWidth = {
            bottom: 1 // only bottom border will be painted
          };
        }
      } else {
        if (data.row.index === 12) {
          data.cell.styles.lineColor = secondaryColor; // cell border color
          data.cell.styles.lineWidth = {
            bottom: 0.5, // only bottom border will be painted
            right: 0.5
          };
        } else {
          data.cell.styles.lineColor = secondaryColor; // cell border color
          data.cell.styles.lineWidth = {
            bottom: 1, // only bottom border will be painted
            right: 0.5
          };
        }
      }
    },
    tableLineColor: secondaryColor,
    tableLineWidth: 0.5
  });
  generateTableCondiciones(doc);
  generatePayTable(doc, structure, priceFinal);
}
const generateTitleTable = (doc, title, color, positionY) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getTextWidth(title);
  const centerX = (pageWidth - textWidth) / 2;
  doc.setFontSize(36);             // Establece el tamaño de la fuente a 24px
  doc.setFont("Lato-Bold", "bold");
  doc.setTextColor(color);
  doc.text(title.toUpperCase(), centerX, positionY);
};

const getTotalDiscounts = (price, items) => {
  let percent = 0;
  let amount = 0;
  items.forEach(e => {
    percent += e.type === "percentage" ? e.value : 0;
    amount += e.type === "fixed" ? e.value : 0;
  });
  return (price * percent) / 100 + amount;
};

const generatePayTable = (doc, structure, priceFinal) => {
  // Apartado o Deposito
  const guarantee_deposit =
    structure.packedPriceRule?.seletedPriceRules?.guarantee_deposit ?? 0;
  // Financiamiento en porcentaje
  const percentage_financing =
    structure.packedPriceRule?.seletedPriceRules?.financing;
  // A cuantos meses se difirio el financiamiento o el precio final
  const months_agreed =
    structure.packedPriceRule?.seletedPriceRules?.months_agreed ?? 1;
  // Con cuanto liquidara en porcentaje
  const percentage_down_payment =
    structure.packedPriceRule?.seletedPriceRules?.percentage_down_payment ??
    100;
  const percentage_financing_interest =
    structure.packedPriceRule?.seletedPriceRules?.financing_interest ??
    undefined;
  const termination_payment =
    structure.packedPriceRule?.seletedPriceRules?.termination_payment ?? 0;

  const enganche = (priceFinal * percentage_down_payment) / 100;
  const financing = percentage_financing
    ? (priceFinal * percentage_financing) / 100
    : undefined;
  doc.addPage();
  const { posY, imgHeight } = imgFrontLogoCenter(doc);
  const title = word(101, language) ?? "Pedido de Compra";
  generateTitleTable(doc, title, "#63656a", posY + imgHeight + 35);
  const wantedTableWidth = 450;
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = (pageWidth - wantedTableWidth) / 2;
  const body = generateBodyPayTable({
    priceFinal: priceFinal,
    guarantee_deposit: guarantee_deposit,
    enganche: enganche,
    financing: financing,
    months_agreed: months_agreed,
    termination_payment: termination_payment,
    percentage_financing_interest: percentage_financing_interest
  });
  doc.autoTable({
    theme: "grid",
    startY: posY + imgHeight + 45,
    head: [
      [
        "Número de pago",
        "Fecha",
        "Capital",
        // "Interés",
        // "Actualización",
        "Enganche",
        "",
        "Saldo Capital"
      ]
    ],
    body: body,
    margin: { left: margin, right: margin },
    bodyStyles: {
      fontSize: 18
    },
    columnStyles: {
      0: {
        fillColor: [220, 221, 222],
        textColor: [0, 0, 0]
      },
      1: {
        fillColor: [220, 221, 222],
        textColor: [0, 0, 0]
      }
    },
    headStyles: {
      fillColor: [99, 101, 106],
      textColor: [255, 255, 255],
      fontSize: 20
    },
    styles: {
      halign: "center",
      fontStyle: "light",
      fillColor: [235, 236, 236],
      textColor: [0, 0, 0],
      cellPadding: { top: 4, right: 8, bottom: 4, left: 8 },
      lineColor: [242, 244, 244]
    }
  });
};

const generateBodyPayTable = ({
  priceFinal,
  guarantee_deposit,
  enganche,
  financing,
  months_agreed,
  termination_payment
  // percentage_financing_interest
}) => {
  let currentDate = new Date();
  let body = [];
  let saldoCapital = priceFinal;
  const rowApartado = [
    "Apartado",
    currentDate.toLocaleDateString(),
    "",
    // "",
    // "",
    monedaFormatter.format(guarantee_deposit),
    monedaFormatter.format(guarantee_deposit),
    ""
  ];
  body.push(rowApartado);
  const rowEnganche = [
    "Enganche",
    currentDate.toLocaleDateString(),
    "",
    // "",
    // "",
    monedaFormatter.format(enganche),
    monedaFormatter.format(enganche),
    ""
  ];
  body.push(rowEnganche);
  // let countEnganche = guarantee_deposit + enganche;
  let countEnganche = enganche;
  saldoCapital = financing ? financing : saldoCapital - enganche;
  // : saldoCapital - guarantee_deposit - enganche;
  const capital = saldoCapital / months_agreed;
  let countCapital = 0;
  Array.from({ length: months_agreed }).map((_, index) => {
    // let interesInsoluto = 0;
    // if (percentage_financing_interest) {
    // interesInsoluto = saldoCapital * (percentage_financing_interest / 100);
    // }
    saldoCapital = saldoCapital - capital;
    const rowMonth = [
      index + 1,
      currentDate.toLocaleDateString(),
      monedaFormatter.format(capital),
      // monedaFormatter.format(interesInsoluto),
      // monedaFormatter.format(0),
      monedaFormatter.format(0),
      monedaFormatter.format(capital),
      monedaFormatter.format(Math.abs(saldoCapital))
    ];
    body.push(rowMonth);
    countCapital = countCapital + capital;
    const currentMonth = currentDate.getMonth();
    currentDate.setMonth(currentMonth + 1);
  });

  const liquidacion = (priceFinal * termination_payment) / 100;
  saldoCapital - liquidacion;
  // const rowLiquidacion = [
  //   "Liquidacion",
  //   currentDate.toLocaleDateString(),
  //   monedaFormatter.format(liquidacion),
  //   monedaFormatter.format(0),
  //   monedaFormatter.format(0),
  //   monedaFormatter.format(0),
  //   `${monedaFormatter.format(saldoCapital)}`
  // ];
  const rowFinal = [
    "",
    "",
    monedaFormatter.format(countCapital),
    // monedaFormatter.format(0),
    // monedaFormatter.format(0),
    monedaFormatter.format(countEnganche),
    monedaFormatter.format(countCapital + countEnganche),
    `${monedaFormatter.format(Math.abs(saldoCapital))}`
  ];
  body.push(rowFinal);
  return body;
};

function getLanguage(structure) {
  return structure?.language ? structure.language : "spanish";
}

const generateTableCondiciones = doc => {
  const wantedTableWidth = 200;
  const firstTableEndPos = doc.lastAutoTable.finalY;
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = (pageWidth - wantedTableWidth) / 2;
  doc.autoTable({
    theme: "plain",
    startY: firstTableEndPos + 20,
    head: [
      [
        {
          content: ""
        },
        {
          content: "CONDICIONES Y OBSERVACIONES"
        }
      ]
    ],
    body: [
      [
        "1)",
        "El bloqueo de la ubicación está sujeto al cumplimiento del plan de pagos del cliente."
      ],
      [
        "2)",
        "Los gastos de escrituración no están incluidos en el plan de pago."
      ],
      [
        "3)",
        "Cualquier cancelación de apartado deberá hacerse en un plazo no mayor a siete días para reintegrar el total de la cantidad apartada."
      ],
      [
        "4)",
        "Una vez firmado el contrato, entrarán en vigor los términos y plazos establecidos en el mismo."
      ],
      [
        "5)",
        "Los costos relativos a contratación de servicios, gastos notariales y cuotas de mantenimiento y fondo de reserva, correrán por cuenta de la compradora."
      ],
      [
        "6)",
        "Operaciones de contado deberán liquidar el precio pactado en los siguientes 7 días naturales contando de la firma de este documento."
      ]
    ],
    margin: { left: margin, right: margin },
    headStyles: {
      fontSize: 16,
      fontStyle: 'bold',
      textColor: "#000000",
      cellPadding: { top: 6, right: 0, bottom: 6, left: 10 },
      fillColor: "#FFFFFF",
    },
    bodyStyles: {
      fontSize: 12,
      fillColor: "#FFFFFF",
    },
    columnStyles: {
      0: {
        cellWidth: 20,
        textColor: "#000000",
        halign: "center",
        fontStyle: "light",
        cellPadding: { top: 3, right: 0, bottom: 3, left: 10 } 
      },
      1: {
        cellWidth: 180,
        textColor: "#000000",
        cellPadding: { top: 3, right: 10, bottom: 3, left: 10 } 
      }
    },
    styles: {
      lineColor: "#000000"
    },
    tableLineWidth: 0.5
  });
};
